// Generated by Framer (e1877f1)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, SVG, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["E_a1tByAN", "Wv4QodMyY"];

const serializationHash = "framer-SdCSs"

const variantClassNames = {E_a1tByAN: "framer-v-wf48wi", Wv4QodMyY: "framer-v-kzc16b"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"L - Green": "Wv4QodMyY", "S - Green": "E_a1tByAN"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "E_a1tByAN"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "E_a1tByAN", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-wf48wi", className, classNames)} data-framer-name={"S - Green"} layoutDependency={layoutDependency} layoutId={"E_a1tByAN"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({Wv4QodMyY: {"data-framer-name": "L - Green"}}, baseVariant, gestureVariant)}><SVG className={"framer-1ozpj69"} data-framer-name={"Check"} layout={"position"} layoutDependency={layoutDependency} layoutId={"f7WAkifVK"} opacity={1} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 20 20\"><g id=\"ss1454878005_1\"><path d=\"M 0 20 L 0 0 L 20 0 L 20 20 Z\" fill=\"transparent\"></path><path d=\"M 7.502 13.475 L 4.027 10 L 2.844 11.175 L 7.502 15.833 L 17.502 5.833 L 16.327 4.658 Z\" fill=\"var(--token-2ff71b15-79ff-4961-a5d5-3c255280b832, rgb(81, 202, 180))\"></path></g></svg>"} svgContentId={1454878005} withExternalLayout {...addPropertyOverrides({Wv4QodMyY: {svg: "<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 32 32\"><g id=\"ss10055128416_1\"><path d=\"M 0 32 L 0 0 L 32 0 L 32 32 Z\" fill=\"transparent\"></path><path d=\"M 12.003 21.56 L 6.443 16 L 4.55 17.88 L 12.003 25.333 L 28.003 9.333 L 26.123 7.453 Z\" fill=\"var(--token-2ff71b15-79ff-4961-a5d5-3c255280b832, rgb(81, 202, 180))\"></path></g></svg>", svgContentId: 10055128416}}, baseVariant, gestureVariant)}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-SdCSs.framer-1mhtwx7, .framer-SdCSs .framer-1mhtwx7 { display: block; }", ".framer-SdCSs.framer-wf48wi { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 20px; justify-content: center; overflow: visible; padding: 0px; position: relative; width: 20px; }", ".framer-SdCSs .framer-1ozpj69 { flex: none; height: 20px; position: relative; width: 20px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-SdCSs.framer-wf48wi { gap: 0px; } .framer-SdCSs.framer-wf48wi > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-SdCSs.framer-wf48wi > :first-child { margin-left: 0px; } .framer-SdCSs.framer-wf48wi > :last-child { margin-right: 0px; } }", ".framer-SdCSs.framer-v-kzc16b.framer-wf48wi, .framer-SdCSs.framer-v-kzc16b .framer-1ozpj69 { height: 32px; width: 32px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 20
 * @framerIntrinsicWidth 20
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"Wv4QodMyY":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramersFJxMUqqC: React.ComponentType<Props> = withCSS(Component, css, "framer-SdCSs") as typeof Component;
export default FramersFJxMUqqC;

FramersFJxMUqqC.displayName = "Pricing/Check icon";

FramersFJxMUqqC.defaultProps = {height: 20, width: 20};

addPropertyControls(FramersFJxMUqqC, {variant: {options: ["E_a1tByAN", "Wv4QodMyY"], optionTitles: ["S - Green", "L - Green"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramersFJxMUqqC, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})